/*$font-family-base:            "Glacial Indifference Regular", sans-serif !default;*/
body {
  font-size: 1.2rem;
}

h1 {
  margin-bottom: 4rem;
  margin-top: 4rem;
}

h2, h3, h4 {
  margin-top: 3rem;
}

h4 {
  text-transform: uppercase;
}

hr {
  border-bottom: 2px solid #ed6167;
  border-top: none;
  margin-bottom: 1.5rem;
  margin-left: 0;
  margin-top: 1.5rem;
  max-width: 4rem;
}

dt {
  font-size: 1rem;
}

.navbar {
  background-color: #fff;
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.08);
  padding-bottom: 0;
  padding-top: 0;
}

.navbar-brand {
  padding-bottom: 0;
  padding-top: 0;
}

.navbar-logo {
  height: 35px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.navbar-nav {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .navbar-nav {
    margin-bottom: 0;
  }
}
.navbar-light .navbar-nav .nav-link {
  font-size: 0.875rem;
  text-align: right;
  text-transform: uppercase;
}

.navbar-light .navbar-nav .active > .nav-link {
  color: #ed6167;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #ed6167;
}

@media (min-width: 768px) {
  .navbar-light .navbar-nav .nav-link {
    border-bottom: 2px solid #fff;
    padding: 21px 1rem;
  }
  .navbar-light .navbar-nav .nav-link:hover {
    border-bottom-color: #ed6167;
    color: #ed6167;
  }
}
.navbar-light .navbar-toggler {
  border: none;
}

@media (min-width: 768px) {
  .form-inline {
    float: right;
    padding-left: 1rem;
  }
}
main {
  min-height: 360px;
}

footer {
  background-color: #2d4d5f;
  color: #f8f9fa;
  font-size: 0.875rem;
  margin-top: 3rem;
  padding-bottom: 2rem;
  padding-top: 1rem;
}

@media (min-width: 768px) {
  footer {
    padding-bottom: 3rem;
    padding-top: 4rem;
  }
}
footer .logo {
  width: 200px;
}

footer .social-links {
  font-size: 1.25rem;
  justify-content: center;
}

@media (min-width: 768px) {
  footer .social-links {
    justify-content: flex-end;
  }
  footer .social-links + p {
    text-align: right;
  }
}
.btn-primary {
  color: #fff;
}

.card-deck .card {
  margin-bottom: 1rem;
}

.card h4 {
  margin-top: 0;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 2;
  }
}
@media (min-width: 768px) {
  .card-columns {
    column-count: 3;
  }
}

@media (min-width: 768px) {
  .column-break {
    break-after: column;
  }
}

table h4 {
  margin-top: 0;
}

legend {
  font-size: 1rem;
}

.custom-control {
  margin-bottom: 0.5rem;
}

.jumbotron {
  background-image: url(/images/heros/home-sm.jpg);
  background-position: center;
  color: #fff;
  height: 575px;
  margin-bottom: 2rem;
}

.contact .jumbotron {
  background-image: url(/images/heros/contact-sm.jpg);
}

.desks .jumbotron {
  background-image: url(/images/heros/desks-sm.jpg);
}

.events .jumbotron {
  background-image: url(/images/heros/events-sm.jpg);
}

.meetings .jumbotron {
  background-image: url(/images/heros/meetings-sm.jpg);
}

.photo-studio .jumbotron {
  background-image: url(/images/heros/photo-studio-sm.jpg);
}

.thanks .jumbotron {
  background-image: url(/images/heros/thanks-sm.jpg);
}

.video-studio .jumbotron {
  background-image: url(/images/heros/video-studio-sm.jpg);
}

.workshops .jumbotron {
  background-image: url(/images/heros/workshops-sm.jpg);
}

@media (min-width: 768px) {
  .jumbotron {
    background-image: url(/images/heros/home-md.jpg);
    height: 600px;
  }
  .contact .jumbotron {
    background-image: url(/images/heros/contact-md.jpg);
  }
  .desks .jumbotron {
    background-image: url(/images/heros/desks-md.jpg);
  }
  .events .jumbotron {
    background-image: url(/images/heros/events-md.jpg);
  }
  .meetings .jumbotron {
    background-image: url(/images/heros/meetings-md.jpg);
  }
  .photo-studio .jumbotron {
    background-image: url(/images/heros/photo-studio-md.jpg);
  }
  .thanks .jumbotron {
    background-image: url(/images/heros/thanks-md.jpg);
  }
  .video-studio .jumbotron {
    background-image: url(/images/heros/video-studio-md.jpg);
  }
  .workshops .jumbotron {
    background-image: url(/images/heros/workshops-md.jpg);
  }
}
.jumbotron .container {
  height: 100%;
  position: relative;
}

.jumbotron .container .positioner {
  bottom: 1rem;
  position: absolute;
}

@media (min-width: 768px) {
  .jumbotron .container .positioner {
    bottom: 4rem;
    left: 4rem;
    right: 4rem;
  }
}
.jumbotron h1 {
  font-weight: bold;
  text-transform: uppercase;
}

.jumbotron h1,
.jumbotron h2 {
  line-height: 1.2;
  margin: 1rem;
}

@media (min-width: 768px) {
  .jumbotron h1 {
    font-size: 4rem;
  }
}
.jumbotron h1 span,
.jumbotron h2 span {
  background-color: rgba(45, 77, 95, 0.75);
}

.thanks .social-links {
  list-style-type: none;
  padding-left: 0;
}

.thanks .social-links .fa {
  width: 2rem;
}

.modal-body > h2 {
  margin-top: 0;
}

img.stars {
  height: 1rem;
  vertical-align: baseline;
}